import { LoadQueryParams } from 'api/walletGroup';
import WalletTypesContextProvider from 'contexts/WalletTypesContext';
import { useEffect } from 'react';
import useURLFilters from 'utils/hooks/useURLFilters';
import { getLastWeekDateRange } from 'utils/date';

import ActionsProvider from './components/ActionsProvider';
import EmptyPlaceholder from './components/EmptyPlaceholder';
import Filter from './components/WalletsLoadFilter';
import WalletsLoadTable from './components/WalletsLoadTable';
import useWalletsLoadListAPI from './hooks/useWalletsLoadListAPI';
import styles from './WalletsLoad.module.scss';

const { dateFrom, dateTo } = getLastWeekDateRange();

export const FILTER_DEFAULT_STATE: LoadQueryParams = {
	direction: "outgoing",
	load_object: "wallets",
	load_type: "transactions_sum",
};

export default function WalletsLoad() {
	const [filterState, setFilterState] = useURLFilters({ initialState: FILTER_DEFAULT_STATE });
	const walletsLoadListAPI = useWalletsLoadListAPI();

	const submitHandler = (value: LoadQueryParams) => {
		const data = { ...value };

		for (const [key, val] of Object.entries(value)) {
			if (val === undefined) {
				delete data[key];
			}
		}

		setFilterState(data);
		walletsLoadListAPI.getList(data);
	};

	useEffect(() => {
		walletsLoadListAPI.getList({
			create_date_from: dateFrom,
			create_date_to: dateTo,
			...filterState,
		});
	}, []);

	return (
		<ActionsProvider>
			<WalletTypesContextProvider>
				<div className={styles.walletsLoad}>
					<div className={styles.content}>
						{walletsLoadListAPI.list === null && <EmptyPlaceholder />}
						{walletsLoadListAPI.list !== null && (
							<WalletsLoadTable
								data={walletsLoadListAPI.list}
								filterState={filterState}
								isLoading={walletsLoadListAPI.isLoading}
							/>
						)}
					</div>
					<div className={styles.controls}>
						<Filter filterInitState={filterState} onSubmit={submitHandler} data-test-id="WalletsLoadFilter" />
					</div>
				</div>
			</WalletTypesContextProvider>
		</ActionsProvider>
	);
}
