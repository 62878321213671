import { t } from '@paykassma/pay-kit';
import { WalletListItem } from 'api/walletGroup';
import { deepClone } from 'helpers/deepClone';

export const isWorkingWallet = (w: WalletListItem) =>
	w.is_active && !w.removed_at;

export const isDisabledWallet = (w: WalletListItem) =>
	!w.is_active && !w.removed_at;

export const isArchivedWallet = (w: WalletListItem) =>
	!w.is_active && w.removed_at !== null;

export const getWalletByType = (
	walletType: string,
	wallets: ReadonlyArray<WalletListItem>,
) => {
	return wallets.filter((w) => w.wallet_type === walletType);
};

export const getWalletsOptions = (
	wallet_type: string,
	wallets: ReadonlyArray<WalletListItem>,
) => {
	const filteredCopy = deepClone(getWalletByType(wallet_type, wallets));

	// TODO: Лучше сделать сортировку по весам для этого дела.
	// Либо в Promise.all или в воркеры.
	const onlyWorked = filteredCopy.filter(isWorkingWallet);
	const onlyDisabled = filteredCopy.filter(isDisabledWallet);
	const onlyArchived = filteredCopy.filter(isArchivedWallet);

	const data = [...onlyWorked, ...onlyDisabled, ...onlyArchived];

	return [
		{ label: t('All') },
		...data.map((w) => ({
			label: `${w.identifier}`,
			value: w.hash_id,
			is_active: w.is_active,
			removed_at: w.removed_at,
		})),
	];
};
